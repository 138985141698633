<template>
  <div class="flex items-center mt-19 pt-8 pb-4 align-middle justify-center">
    <div class="error-container">
      <!-- <Link :to="{ name: 'index' }"> -->
      <div class="block justify-start flex-row">
        <div class="h-12 flex align-middle justify-start">
          <bnsl-logo class="w-full -ml-1 max-h-full" />
        </div>
      </div>
      <!-- </Link> -->
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.error-container {
  background: url('https://content-bsnl.s3.eu-west-1.amazonaws.com/logo/bounsel404.svg')
    center right no-repeat;
  background-size: 200px;
  padding-right: 205px;
}
</style>
